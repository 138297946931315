import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  inject,
  model,
  OnInit,
  signal,
  WritableSignal,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IGetCADetailsResponse } from '../../shared/types/api-types';
import { SalesBeApiService } from '../../core/sales-be-api.service';
import { finalize } from 'rxjs';
import { CardModule } from 'primeng/card';
import { ChipModule } from 'primeng/chip';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { AudioPlayerComponent } from '../../shared/components/audio-player/audio-player.component';
import { TabViewModule } from 'primeng/tabview';
import { ChartModule } from 'primeng/chart';
import { dayjsUtil } from '../../shared/utils/dayjs.util';
import { MarkdownComponent } from 'ngx-markdown';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { FormsModule } from '@angular/forms';
import { SkeletonModule } from 'primeng/skeleton';
import { GlobalStateService } from '../../core/global-state.service';
import { CALLS_ANALYSIS_PATH } from '../../shared/constants/routes.constants';
import { TalkingTimeCardComponent } from '../../shared/components/call-analysis/talking-time-card/talking-time-card.component';
import { Button } from 'primeng/button';

interface Message {
  speaker: 'agent' | 'customer';
  timestamp: string;
  content: string;
  isRTL: boolean;
}

@Component({
  selector: 'swa-call-analysis-detail',
  imports: [
    CardModule,
    ChipModule,
    DatePipe,
    AudioPlayerComponent,
    TabViewModule,
    ChartModule,
    MarkdownComponent,
    TitleCasePipe,
    ToggleButtonModule,
    FormsModule,
    SkeletonModule,
    TalkingTimeCardComponent,
    Button,
  ],
  templateUrl: './call-analysis-detail.component.html',
  styleUrl: './call-analysis-detail.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CallAnalysisDetailComponent implements OnInit {
  private destroyRef = inject(DestroyRef);
  selectedCallId: WritableSignal<number | undefined> = signal<number | undefined>(undefined);

  callDetails = signal<IGetCADetailsResponse | undefined>(undefined);
  isDataLoading = signal(false);

  showCallHighlightsInEnglish = model(true);

  transformedTranscript = computed<Message[] | undefined>(() => {
    const callDetails = this.callDetails();
    if (!(callDetails && callDetails.transcript)) {
      return;
    }

    const messages: Message[] = [];
    const pattern =
      /(?:^|\s)(agent|customer)\s*\((\d+\.\d+-\d+\.\d+)\):\s*([^]*?)(?=\s+(?:agent|customer)\s*\(|\s*$)/gi;

    let match;
    while ((match = pattern.exec(callDetails.transcript)) !== null) {
      const [, speaker, timestamp, content] = match;
      const [startTime, endTime] = timestamp.split('-');

      messages.push({
        speaker: speaker.toLowerCase() as 'agent' | 'customer',
        timestamp: `${dayjsUtil.duration(Number(startTime), 'seconds').format('HH:mm:ss')} - ${dayjsUtil.duration(Number(endTime), 'seconds').format('HH:mm:ss')}`,
        content: content.trim(),
        isRTL: /[\u0591-\u07FF\uFB1D-\uFDFD\uFE70-\uFEFC]/.test(content),
      });
    }

    return messages;
  });

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private salesBeApiService: SalesBeApiService,
    private globalStateService: GlobalStateService,
  ) {}

  ngOnInit() {
    this.route.paramMap.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((params) => {
      const callId = Number(params.get('callId'));
      this.selectedCallId.set(callId);
      this.updateHeaderDetails();
      this.fetchCallDetails();
    });
  }

  private updateHeaderDetails() {
    this.globalStateService.headerDetails.set({
      title: `Call ID: ${this.selectedCallId()}`,
      onBackButtonClick: () => {
        if (history.length > 1) {
          history.back();
        } else {
          this.router.navigate([CALLS_ANALYSIS_PATH]);
        }
      },
    });
  }

  private fetchCallDetails() {
    if (this.selectedCallId()) {
      this.isDataLoading.set(true);
      this.salesBeApiService
        .getCallAnalysisDetails(this.selectedCallId()!)
        .pipe(finalize(() => this.isDataLoading.set(false)))
        .subscribe((callDetails) => {
          this.callDetails.set(callDetails);
        });
    }
  }

  toggleCallHighlightsLanguage() {
    this.showCallHighlightsInEnglish.update((showCallHighlightsInEnglish) => !showCallHighlightsInEnglish);
  }
}
