import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { ToastModule } from 'primeng/toast';
import { Title } from '@angular/platform-browser';
import { filter, map, mergeMap } from 'rxjs';
import { GoogleAnalyticsService } from './core/google-analytics.service';

@Component({
  selector: 'swa-root',
  imports: [RouterOutlet, ToastModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  constructor(
    private googleAnalyticsService: GoogleAnalyticsService,
    private titleService: Title,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.subscribeRouterTitleEvent();
  }

  subscribeRouterTitleEvent() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        mergeMap((route) => route.data),
      )
      .subscribe((data) => {
        const pageTitle = data['title'] || 'SalesWebApp';
        this.titleService.setTitle(pageTitle);
        this.googleAnalyticsService.loadGoogleAnalytics();
      });
  }
}
