import { dayjsUtil } from './dayjs.util';

export const formatSecondsToTime = (seconds: number) => {
  const duration = dayjsUtil.duration(seconds, 'seconds');
  const hours = Math.floor(duration.asHours());
  const minutes = duration.minutes();
  const secs = duration.seconds();

  let result = '';
  if (hours > 0) {
    result += `${hours}h `;
  }
  if (minutes > 0) {
    result += `${minutes}m `;
  }
  if (secs > 0) {
    result += `${secs}s `;
  }
  if (!result) {
    result += '0s';
  }
  return result;
};

export const formatSecondsToTimeDotSplitted = (seconds: number) => {
  const duration = dayjsUtil.duration(seconds, 'seconds');
  const hours = Math.floor(duration.asHours());
  const minutes = duration.minutes();
  const secs = duration.seconds();

  return `${hours}h:${minutes}m:${secs}s`;
};
