import { Component, computed, input, signal } from '@angular/core';
import { CardModule } from 'primeng/card';
import { ChartModule } from 'primeng/chart';
import { ChartOptions } from 'chart.js';
import { dayjsUtil } from '../../../utils/dayjs.util';
import { IGetCADetailsResponse } from '../../../types/api-types';
import { formatSecondsToTime, formatSecondsToTimeDotSplitted } from '../../../utils/time.utils';

@Component({
  selector: 'swa-talking-time-card',
  imports: [CardModule, ChartModule],
  templateUrl: './talking-time-card.component.html',
  styleUrl: './talking-time-card.component.scss',
})
export class TalkingTimeCardComponent {
  callDetails = input<IGetCADetailsResponse | undefined>(undefined);

  doughnutOptions = signal<ChartOptions<'doughnut'>>({
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => {
            const value = context.raw || 0;
            return ` ${value}%`;
          },
        },
      },
      legend: {
        position: 'bottom',
        labels: {
          font: {
            size: 12,
          },
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    cutout: '80%',
  });

  totalTalkingTime = computed(() => {
    const callDetails = this.callDetails();
    if (!callDetails?.customer_speaking_duration || !callDetails?.agent_speaker_duration) {
      return 0;
    }
    return callDetails.customer_speaking_duration + callDetails.agent_speaker_duration;
  });

  customerSpeakingPercentage = computed(() => {
    const talkingTime = this.totalTalkingTime();
    const callDetails = this.callDetails();
    if (!talkingTime || !callDetails) {
      return;
    }

    const customerSpeakingDuration = dayjsUtil.duration(callDetails.customer_speaking_duration ?? 0, 'seconds');
    return ((customerSpeakingDuration.asSeconds() / talkingTime) * 100).toFixed(2);
  });

  agentSpeakingPercentage = computed(() => {
    const talkingTime = this.totalTalkingTime();
    const callDetails = this.callDetails();
    if (!talkingTime || !callDetails) {
      return;
    }
    const agentSpeakingDuration = dayjsUtil.duration(callDetails.agent_speaker_duration ?? 0, 'seconds');
    return ((agentSpeakingDuration.asSeconds() / talkingTime) * 100).toFixed(2);
  });

  transformedDurationDoughnutData = computed(() => {
    const customerSpeakingDuration = this.customerSpeakingPercentage();
    const agentSpeakingDuration = this.agentSpeakingPercentage();
    if (!customerSpeakingDuration || !agentSpeakingDuration) {
      return;
    }

    return {
      datasets: [
        {
          data: [customerSpeakingDuration, agentSpeakingDuration],
          backgroundColor: ['#BA1B1B', '#5C7070'],
          hoverBackgroundColor: ['#BA1B1B', '#5C7070'],
        },
      ],
    };
  });

  protected readonly formatSecondsToTime = formatSecondsToTime;
  protected readonly formatSecondsToTimeDotSplitted = formatSecondsToTimeDotSplitted;
}
