import 'vidstack/player';
import 'vidstack/player/layouts/default';
import 'vidstack/player/ui';

import { ChangeDetectionStrategy, Component, CUSTOM_ELEMENTS_SCHEMA, input, InputSignal, output } from '@angular/core';
import { ToastService } from '../../../core/toast.service';

interface IMediaError {
  code: number;
  message: string;
}

@Component({
  selector: 'swa-audio-player',
  imports: [],
  templateUrl: './audio-player.component.html',
  styleUrl: './audio-player.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AudioPlayerComponent {
  title: InputSignal<string> = input('');
  src: InputSignal<string> = input.required();

  onError = output<IMediaError>();

  constructor(private toastService: ToastService) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onMediaPlayerError(event: any) {
    this.onError.emit({
      code: event.detail.code,
      message: event.detail.message,
    });

    this.toastService.mediaPlayerError(event.detail.message);
  }
}
