import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideNgxWebstorage, withLocalStorage, withNgxWebstorageConfig } from 'ngx-webstorage';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { MessageService } from 'primeng/api';
import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { provideMarkdown } from 'ngx-markdown';
import { httpErrorInterceptor } from './shared/interceptors/http-error.interceptor';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideAnimationsAsync(),
    provideNgxWebstorage(withNgxWebstorageConfig({ caseSensitive: true, prefix: 'rewaa-sales' }), withLocalStorage()),
    provideHttpClient(withFetch(), withInterceptors([httpErrorInterceptor])),
    provideMarkdown(),
    provideRouter(routes),
    { provide: MessageService, useClass: MessageService },
  ],
};
