@let _isSideNavOpened = isSideNavOpened();
@let _headerDetails = headerDetails();
@let _breadCrumbs = breadCrumbs();

<div class="flex flex-column h-screen">
  <!-- CONTENT -->
  <div
    class="rw-main"
    [class.rw-sidenav-closed]="!_isSideNavOpened"
  >
    <!-- Side Nav -->
    <div class="rw-sidenav">
      <!-- Side Nav top -->
      <div class="rw-sidenav-top">
        <div class="rw-logo cursor-pointer">
          @if (_isSideNavOpened) {
            <img
              [routerLink]="homePageLink"
              src="images/logo.svg"
              alt="Rewaa Admin"
            />
          } @else {
            <img
              [routerLink]="homePageLink"
              src="images/collapsed-logo.svg"
              alt="Rewaa Admin"
            />
          }
        </div>
        @if (_isSideNavOpened) {
          <button
            pButton
            pRipple
            icon="fa-solid fa-bars"
            aria-label="Toggle Side Nav"
            class="p-button-rounded p-button-secondary p-button-text header-menu-btn"
            (click)="handleSideNavToggleClicked()"
          ></button>
        }
      </div>
      <!-- end Side Nav top -->
      <div class="sidenav-container">
        <p-panelMenu [model]="menuItems()"></p-panelMenu>
      </div>
    </div>
    <!-- end Side Nav -->
    <div class="rw-main-content">
      <!-- HEADER -->
      <div class="header">
        <ul class="header-col list-style-list">
          <li class="header-toggle-btn">
            <button
              pButton
              pRipple
              icon="fa-solid fa-bars"
              aria-label="Toggle Side Nav"
              class="p-button-rounded p-button-secondary p-button-text header-menu-btn"
              (click)="handleSideNavToggleClicked()"
            ></button>
          </li>

          @if (_headerDetails.onBackButtonClick) {
            <li class="hidden sm:block">
              <button
                pButton
                pRipple
                icon="fa-solid fa-arrow-left"
                aria-label="Go back"
                (click)="_headerDetails.onBackButtonClick()"
                class="p-button-rounded back-btn p-button-text"
              ></button>
            </li>
          }
          @if (_headerDetails.title) {
            <li>
              <h4 class="header-title">{{ _headerDetails.title }}</h4>
            </li>
          }
        </ul>
        <ul class="header-col list-style-list header-action-list">
          <!--          <i>-->
          <!--            <button-->
          <!--              pButton-->
          <!--              pRipple-->
          <!--              pBadge-->
          <!--              icon="fa-regular fa-question-circle"-->
          <!--              aria-label="Show Help"-->
          <!--              class="p-button-rounded p-button-secondary p-button-text"-->
          <!--            ></button>-->
          <!--          </i>-->

          <!--          <li>-->
          <!--            &lt;!&ndash; Notification popover &ndash;&gt;-->
          <!--            &lt;!&ndash; <rw-notification-overlay #overlay (bellCssClass)="onBellCssClassChange($event)" (click)="overlay.toggle($event)">-->
          <!--            </rw-notification-overlay> &ndash;&gt;-->
          <!--            &lt;!&ndash; Notification popover end&ndash;&gt;-->
          <!--            <button-->
          <!--              pButton-->
          <!--              pRipple-->
          <!--              pBadge-->
          <!--              badge="•"-->
          <!--              icon="fa-regular fa-bell"-->
          <!--              aria-label="Show Notifications"-->
          <!--              class="p-button-rounded p-button-secondary p-button-text"-->
          <!--            ></button>-->
          <!--          </li>-->
          <li>
            <p-menu
              #userMenu
              [model]="userMenuItems()"
              appendTo="body"
              [popup]="true"
            ></p-menu>
            <p-button
              class="block"
              styleClass="user-info-button"
              type="button"
              [text]="true"
              (click)="userMenu.toggle($event)"
            >
              <div class="user-info-title flex flex-column align-items-start gap-1">
                <span class="user-info-name flex align-items-center gap-2">
                  <span>{{ loggedInUser() || '' }}</span>
                  <i class="fa-solid fa-angle-down"></i>
                </span>
                <span class="user-info-role">{{ loggedInUserRole()[0] || 'guest' | titlecase }}</span>
              </div>
            </p-button>
          </li>
        </ul>
      </div>
      <div class="rw-main-content-header">
        @if (_breadCrumbs.length > 0) {
          <p-breadcrumb
            class="max-w-full"
            [model]="_breadCrumbs"
            [home]="breadcrumbHome"
          ></p-breadcrumb>
        }
      </div>
      <div class="rw-main-content-body">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
