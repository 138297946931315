import { Routes } from '@angular/router';
import { ShellComponent } from './shell/shell.component';
import { CALLS_ANALYSIS_ROUTE, LOGIN_ROUTE } from './shared/constants/routes.constants';
import { unAuthenticatedGuard } from './shared/route-guards/un-authenticated.guard';
import { authenticatedGuard } from './shared/route-guards/authenticated.guard';
import { removeUrlTokenGuard } from './shared/route-guards/remove-url-token.guard';
import { CallAnalysisListComponent } from './call-analysis/call-analysis-list/call-analysis-list.component';
import { CallAnalysisDetailComponent } from './call-analysis/call-analysis-detail/call-analysis-detail.component';

export const routes: Routes = [
  {
    path: LOGIN_ROUTE,
    loadComponent: () => import('./login/login.component').then((m) => m.LoginComponent),
    canActivate: [unAuthenticatedGuard],
  },
  {
    path: '',
    component: ShellComponent,
    children: [
      {
        path: '',
        redirectTo: CALLS_ANALYSIS_ROUTE,
        pathMatch: 'full',
        data: { title: 'Call Analysis' },
      },
      // {
      //   path: HOME_ROUTE,
      //   loadComponent: () => import('./home/home.component').then((m) => m.HomeComponent),
      //   canActivate: [authenticatedGuard, removeUrlTokenGuard],
      // },
      {
        path: CALLS_ANALYSIS_ROUTE,
        loadComponent: () => import('./call-analysis/call-analysis.component').then((m) => m.CallAnalysisComponent),
        canActivate: [authenticatedGuard, removeUrlTokenGuard],
        children: [
          {
            path: '',
            component: CallAnalysisListComponent,
            pathMatch: 'full',
            data: { title: 'Call Analysis' },
          },
          {
            path: ':callId',
            component: CallAnalysisDetailComponent,
            data: { title: 'Call Analysis Details' },
          },
        ],
      },
    ],
  },
  {
    path: '**',
    redirectTo: '',
  },
];
