import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

export class BaseHttpClient {
  constructor(
    private readonly baseUrl: string,
    private readonly http: HttpClient,
  ) {}

  private buildUrl(endpoint: string): string {
    return this.baseUrl + endpoint;
  }

  get<T>(endpoint: string, options?: { headers?: HttpHeaders; params?: HttpParams }): Observable<T> {
    return this.http.get<T>(this.buildUrl(endpoint), options);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  post<T>(endpoint: string, body: any, options?: { headers?: HttpHeaders; params?: HttpParams }): Observable<T> {
    return this.http.post<T>(this.buildUrl(endpoint), body, options);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  put<T>(endpoint: string, body: any, options?: { headers?: HttpHeaders; params?: HttpParams }): Observable<T> {
    return this.http.put<T>(this.buildUrl(endpoint), body, options);
  }

  delete<T>(endpoint: string, options?: { headers?: HttpHeaders; params?: HttpParams }): Observable<T> {
    return this.http.delete<T>(this.buildUrl(endpoint), options);
  }
}
