<span
  #inputSpan
  class="text-ellipsis"
  [style.max-width]="maxWidth"
  [pTooltip]="tooltipContent"
  [tooltipDisabled]="!isOverflowing()"
  tooltipPosition="top"
>
  {{ text }}
</span>
<ng-template #tooltipContent>
  <div class="flex align-items-center text-xs">
    <p>{{ text }}</p>
  </div>
</ng-template>
