@let _callDetails = callDetails();
<div class="call-analysis-detail-page">
  @if (_callDetails) {
    <div class="top-banner mb-5">
      <p-card>
        <div class="flex flex-col xl:flex-row justify-content-center align-items-center gap-2 text-sm">
          <div class="flex justify-center items-center gap-x-4">
            <p-chip [label]="_callDetails.status ?? '?'"></p-chip>
            <p class="flex items-center !mb-0 !mr-0">
              <i class="pi pi-wallet mr-2"></i>
              Budget: {{ _callDetails.estimated_budget ? 'SR ' + _callDetails.estimated_budget : '?' }}
            </p>
            <p class="flex align-items-center !mb-0 !mr-0">
              <i class="pi pi-user mr-2"></i>
              Agent: {{ _callDetails.agent_name || '?' }}
            </p>
          </div>
          <div class="flex justify-center items-center gap-x-4">
            <p class="flex align-items-center !mb-0 !mr-0">
              <i class="pi pi-file mr-2"></i>
              Account: {{ _callDetails.customer_name || '?' }}
            </p>
            <p class="flex align-items-center !mb-0 !mr-0">
              <i class="pi pi-calendar mr-2"></i>
              Date: {{ _callDetails.datetime | date: 'dd MMM YYYY' }}
            </p>
            <p class="flex align-items-center !mb-0 !mr-0">
              <i class="pi pi-clock mr-2"></i>
              Time: {{ _callDetails.datetime | date: 'hh:mm a' }}
            </p>
          </div>
        </div>
      </p-card>
    </div>

    <div>
      @if (_callDetails.call_url) {
        <div class="audio-frame">
          <div class="audio-player-container">
            <swa-audio-player [src]="_callDetails.call_url!" />
          </div>
        </div>
      }
    </div>

    <div class="flex mt-7">
      <div class="flex-column mr-3">
        <div
          class="mb-3 font-bold text-2xl"
          [style.color]="'#6A3869'"
        >
          Quick Insights
        </div>
        @if (_callDetails.duration) {
          <swa-talking-time-card [callDetails]="_callDetails" />
        }
      </div>

      <div class="flex-1 call-details-metrics">
        <div
          class="mb-3 font-bold text-2xl"
          [style.color]="'#6A3869'"
        >
          Call Details
        </div>
        <div>
          <p-tabView>
            <p-tabPanel>
              <ng-template pTemplate="header">
                <div class="flex align-items-center">
                  <i class="pi pi-sparkles mr-2"></i>
                  Call Highlights
                </div>
              </ng-template>

              <div class="call-highlights">
                <div class="mb-3 flex align-items-center">
                  <p-button
                    iconPos="right"
                    icon="fa fa-language fa-solid"
                    [label]="showCallHighlightsInEnglish() ? 'عربي' : 'English'"
                    [outlined]="true"
                    (onClick)="toggleCallHighlightsLanguage()"
                  />
                </div>

                <div
                  [class]="{ rtl: !showCallHighlightsInEnglish(), ltr: showCallHighlightsInEnglish() }"
                  class="custom-markdown"
                >
                  <markdown
                    [data]="showCallHighlightsInEnglish() ? _callDetails.summary_en : _callDetails.summary_ar"
                  ></markdown>
                </div>
              </div>
            </p-tabPanel>

            <p-tabPanel>
              <ng-template pTemplate="header">
                <div class="flex align-items-center">
                  <i class="pi pi-file-edit mr-2"></i>
                  Transcript
                </div>
              </ng-template>
              <div class="transcript">
                <div class="conversation-container">
                  @for (message of transformedTranscript(); track message.timestamp) {
                    <div
                      class="message"
                      [class]="message.speaker"
                    >
                      <div class="message-header">
                        <span class="speaker">{{ message.speaker | titlecase }}</span>
                        <span class="timestamp">{{ message.timestamp }}</span>
                      </div>
                      <div
                        class="message-bubble"
                        [class]="{ rtl: message.isRTL, ltr: !message.isRTL }"
                      >
                        {{ message.content }}
                      </div>
                    </div>
                  }
                </div>
              </div>
            </p-tabPanel>
          </p-tabView>
        </div>
      </div>
    </div>
  } @else {
    <div class="mb-3">
      <p-skeleton
        width="100%"
        height="3rem"
      />
    </div>
    <div class="mb-3">
      <p-skeleton
        width="100%"
        height="3rem"
      />
    </div>
    <div class="flex flex-row gap-3">
      <div class="w-15rem">
        <p-skeleton
          width="100%"
          height="15rem"
        />
      </div>
      <div class="w-30rem">
        <p-skeleton
          width="100%"
          height="15rem"
        />
      </div>
    </div>
  }
</div>
