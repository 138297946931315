import { Injectable } from '@angular/core';
import { BaseHttpClient } from './base-http-client';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class SalesApiHttpClient extends BaseHttpClient {
  constructor(private readonly httpClient: HttpClient) {
    super(environment.salesApiUrl, httpClient);
  }
}
