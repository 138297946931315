<p-card>
  <div class="flex align-items-center font-bold">
    <i class="pi pi-comment mr-2"></i>
    <span>Talking time ratio</span>
  </div>
  <div>
    @if (callDetails()) {
      <div class="mb-3 mt-2">
        <strong>Total Speaking Duration:</strong>
        {{ formatSecondsToTimeDotSplitted(totalTalkingTime()) }}
      </div>
      <div class="flex gap-2 items-center">
        <div class="w-60">
          <p-chart
            type="doughnut"
            [data]="transformedDurationDoughnutData()"
            [options]="doughnutOptions()"
          ></p-chart>
        </div>
        <div class="w-52 text-sm">
          <div class="flex gap-x-3 items-center mb-5">
            <div class="w-3 h-1 rounded bg-rRed-700"></div>
            <div class="max-w-32">
              <p>Account ({{ callDetails()?.customer_name }})</p>
              <p class="text-rRed-700">
                {{ formatSecondsToTime(callDetails()?.customer_speaking_duration ?? 0) }} -
                {{ customerSpeakingPercentage() }}%
              </p>
            </div>
          </div>
          <div class="flex gap-x-3 items-center">
            <div class="w-3 h-1 rounded bg-rNeutral-700"></div>
            <div class="max-w-32">
              <p>Agent ({{ callDetails()?.agent_name }})</p>
              <p class="text-rNeutral-700">
                {{ formatSecondsToTime(callDetails()?.agent_speaker_duration ?? 0) }} - {{ agentSpeakingPercentage() }}%
              </p>
            </div>
          </div>
        </div>
      </div>
    }
  </div>
</p-card>
