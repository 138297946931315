import { AfterViewInit, Component, ElementRef, Input, signal, ViewChild } from '@angular/core';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'swa-cropped-text',
  imports: [TooltipModule],
  templateUrl: './cropped-text.component.html',
  styleUrl: './cropped-text.component.scss',
})
export class CroppedTextComponent implements AfterViewInit {
  @Input() text = '';
  @Input() maxWidth = '200px';

  @ViewChild('inputSpan')
  inputSpan!: ElementRef;

  isOverflowing = signal(false);

  ngAfterViewInit(): void {
    setTimeout(() => {
      const element = this.inputSpan.nativeElement;
      this.isOverflowing.set(element.scrollWidth > element.clientWidth);
    }, 1000);
  }
}
