import { Component, Input } from '@angular/core';

@Component({
  selector: 'swa-listing-anchor',
  imports: [],
  templateUrl: './listing-anchor.component.html',
  styleUrl: './listing-anchor.component.scss',
})
export class ListingAnchorComponent {
  @Input()
  link!: string;

  @Input()
  isExternal = false;
}
