<media-player
  load="visible"
  view-type="audio"
  [attr.title]="title()"
  [attr.src]="src()"
  (error)="onMediaPlayerError($event)"
>
  <media-provider></media-provider>
  <media-audio-layout color-scheme="light"></media-audio-layout>
</media-player>
